<template>
  <div id="account-setting-payout" class="container">

    <div class="page-header mb-1">
      <strong>ตั้งค่า ขั้นต่ำ/สูงสุด/สูงสุดต่อเลข</strong>
    </div>

    <ol class="breadcrumb px-2 py-1 m-0 mb-2 border-info">
      <li class="breadcrumb-item" v-for="breadcrumb in Breadcrumbs" :class="[{'active': breadcrumb.accountId===parentAccountId}]">
        <span v-if="breadcrumb.accountId===parentAccountId">{{ breadcrumb.username }}</span>
        <a v-else href="#" @click="toggleChildAccount(breadcrumb.accountId)">{{ breadcrumb.username }}</a>
      </li>
    </ol>

    <div class="card">
      <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs">
          <li v-for="group in groups" :key="group._id" class="nav-item">
            <span class="nav-link" :class="[{'active':tabActiveGroup===group._id}]" @click="tabActiveGroupId=group._id">{{ group.groupTitle }}</span>
          </li>
        </ul>
      </div>

      <div class="p-2">
        <table class="table-accounts table table-hover table-bordered mb-0">
          <thead class="alert-warning">
            <tr>
              <th class="text-center" rowspan="3">ลำดับ</th>
              <th class="text-center" rowspan="2">เลือก</th>
              <th class="text-center">
                ชื่อใช้งาน
                <b-form-checkbox
                  v-model="showName"
                  :value="true"
                  :unchecked-value="false"
                  class="mt-2 font-weight-normal"
                >
                  แสดงชื่อ
                </b-form-checkbox>
              </th>
              <th class="text-center">ระดับ</th>
              <th :colspan="marketOpenBets.length" class="text-center">
                <b-button-group>
                  <b-button
                    :variant="tabActiveButton==='min'?'info':'outline-info'"
                    @click="tabActiveButton='min'"
                    style="width: 150px;"
                  >ขั้นต่ำ</b-button>
                  <b-button
                    :variant="tabActiveButton==='max'?'info':'outline-info'"
                    @click="tabActiveButton='max'"
                    style="width: 150px;"
                  >สูงสุด</b-button>
                  <b-button
                    :variant="tabActiveButton==='maxPerNumber'?'info':'outline-info'"
                    @click="tabActiveButton='maxPerNumber'"
                    style="width: 150px;"
                  >สูงสุดต่อเลข</b-button>
                </b-button-group>
              </th>
              <th class="text-center" rowspan="2">แก้ไข</th>
            </tr>
            <tr>
              <th colspan="2" rowspan="2" class="text-center">
                <multiselect
                  v-model="selectedGroups"
                  tag-placeholder="ค้นหาหวย"
                  placeholder="เลือกหวย"

                  group-values="groups"
                  group-label="title"
                  :group-select="true"
                  label="groupTitle"
                  track-by="_id"

                  selectLabel="เลือก"
                  :options="groupOptions"
                  :multiple="true"
                  :taggable="true"
                  :hideSelected="true"
                  :disabled="!bulkAccountIds.length"
                ></multiselect>
              </th>
              <th v-for="bet in marketOpenBets" :key="bet.code" class="text-center">{{ bet.text }}</th>
            </tr>
            <tr>
              <th class="text-center p-0">
                <b-form-checkbox :disabled="!permSetting" v-model="checkAll" class="m-0" :value="true" :unchecked-value="false" />
              </th>
              <th v-for="bet in marketOpenBets" :key="bet.code" class="text-center p-1">
                <b-form-input
                  size="sm"
                  :number="true"
                  v-model="inputBulk[bet.code]"
                  :disabled="!bulkAccountIds.length"
                >
                </b-form-input>
              </th>
              <th class="text-center p-0">
                <b-button variant="success" size="sm" :disabled="!bulkAccountIds.length" @click="saveBulk">บันทึก</b-button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(account, index) in items" :key="account._id">
              <td width="5%" class="text-center">{{ index+1 }}</td>
              <td width="5%" class="text-center">
                <b-form-checkbox
                  :disabled="!permSetting"
                  v-model="bulkAccountIds"
                  :value="account._id"
                />
              </td>
              <td class="text-center p-0">
                <div class="username">
                  <a v-if="account.role==='Agent'" href="#" @click="toggleChildAccount(account._id)">{{ account.user.username }}</a>
                  <span v-else>{{ account.user.username }}</span>
                </div>
                <small v-if="showName" class="text-secondary">({{ account.user.firstName }})</small>
              </td>
              <td width="12%" class="text-center"><AccountRole :role="account.role" /></td>
              <td width="8%" v-for="bet in marketOpenBets"
                :key="bet.code"
                class="text-right"
              >
                <span>{{ account.groups[tabActiveGroupId].betLimit[bet.code][tabActiveButton] | numberFormat }}</span>
              </td>
              <td width="6%" class="text-center">
                <button :disabled="!permSetting" class="btn btn-sm btn-outline-primary" @click="edit(account)"><i class="far fa-edit"></i></button>
              </td>
            </tr>
            <tr v-if="!items.length">
              <td class="text-center" :colspan="marketOpenBets.length + 5">ไม่มีข้อมูล</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <EditBetModal :data="editData" :refData="refData" :is-show="isShowModal" @close="modalClosed" />
  </div>
</template>
<script>
import AccountSettingService from '@/services/AccountSettingService'
import Swal from 'sweetalert2'
import cAlert from '@/helpers/alert'

import { marketOpenBets } from "@/configs/market.config"

import EditBetModal from './components/EditBetModal'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

const inputBulk = {
  threeNumberTop: '',
  threeNumberTode: '',
  twoNumberTop: '',
  twoNumberBottom: '',
  runTop: '',
  runBottom: ''
}

export default {
  name: 'SettingBet',
  components: {
    EditBetModal,
    Multiselect
  },
  data() {
    return {
      tabActiveGroupId: null,
      accounts: [],
      refSetting: {},
      Breadcrumbs: [],
      accountId: this.$store.state.account._id,
      tabActiveButton: 'min',
      editData: null,
      isShowModal: false,
      showName: false,
      inputBulk: JSON.parse(JSON.stringify(inputBulk)),
      bulkAccountIds: [],
      selectedGroups: [],
      checkAll: false,
    }
  },
  computed: {
    groups() {
      return this.$store.state.marketGroups.filter((group)=>{
        return group.status === 'Active' && group.groupType === 'single' && this.refSetting[group._id]?.isAvailable
      })
      .map((group)=>{
        return {
          _id: group._id,
          groupTitle: group.groupTitle
        }
      })
    },
    tabActiveGroup() {
      return this.tabActiveGroupId
    },
    items() {
      return this.accounts.filter((acc)=>{
        return acc.groups[this.tabActiveGroupId].isAvailable
      })
    },
    currentAccountId() {
      return this.$store.state.account._id
    },
    parentAccountId() {
      if(this.Breadcrumbs.length === 0) {
        return this.currentAccountId
      }else{
        return this.Breadcrumbs[this.Breadcrumbs.length - 1].accountId
      }
    },
    marketOpenBets() {
      return marketOpenBets
    },
    refData() {
      return this.refSetting[this.tabActiveGroupId]?.betLimit
    },
    checkChangeTab() {
      return `${this.tabActiveGroupId}${this.tabActiveButton}`
    },
    groupOptions() {
      return this.groups.reduce((options, group)=>{
        options[0].groups.push(group)
        return options
      }, [{
        title: 'เลือกทั้งหมด',
        groups: []
      }])
    },
    userRole() {
      return this.$store.state.userProfile.userRole
    },
    permSetting() {
      const permission = this.$store.state.account?.permission || {}

      return this.userRole === 'Owner' || permission?.MemberBetLimit === 'w'
    }
  },
  watch: {
    checkChangeTab() {
      this.resetBulk()
    },
    checkAll(n, o) {
      this.bulkAccountIds = []
      if(n){
        for(const item of this.items) {
          this.bulkAccountIds.push(item._id)
        }
      }
    },
    tabActiveGroup(n, o) {
      if(n) {

        /**
         * ตั้งค่าเลือกกลุ่ม
         */
        const group = this.groups.find((g)=>{
          return g._id === n
        })
        if(group) {
          this.selectedGroups = [{...group}]
        }
      }
    }
  },
  methods: {
    loadAccounts() {
      AccountSettingService.getAccountSettingBet(this.accountId)
      .then((response) => {
        if(response.success) {
          this.accounts = response.data.accounts
          this.Breadcrumbs = response.data.parents
          this.refSetting = response.data.refSetting

          if(!this.tabActiveGroupId) {
            this.tabActiveGroupId = this.groups[0]?._id
          }
        }
      })
    },
    toggleChildAccount(accountId) {
      this.resetBulk()
      this.accountId = accountId
      this.loadAccounts()
    },
    edit(account) {
      this.editData = {
        account,
        bet: {
          accountId: account._id,
          groupId: this.tabActiveGroupId,
          limit: this.tabActiveButton,
          betLimit: account.groups[this.tabActiveGroupId].betLimit
        }
      }
      this.isShowModal = true
    },
    modalClosed(needReload) {
      this.isShowModal = false
      if(needReload) {
        this.loadAccounts()
      }
    },
    saveBulk() {

      try {

        if(!this.bulkAccountIds.length)
          throw new Error('กรุณาเลือกสมาชิก')

        if(!this.selectedGroups.length)
          throw new Error('กรุณาเลือกกลุ่มหวย')

        let countInput = 0
        const betLimit = marketOpenBets.reduce((rate, bet)=>{
          if(this.inputBulk[bet.code] !== '') {
            rate[bet.code] = {}
            rate[bet.code][this.tabActiveButton] = this.inputBulk[bet.code]
            countInput++
          }
          return rate
        }, {})

        if(countInput === 0)
          throw new Error('กรุณากรอกข้อมูลอย่างน้อย 1 ช่อง')

        /**
         * ตรวจสอบจำนวน
         */
        for(const bet of marketOpenBets) {
          if(this.inputBulk[bet.code] > this.refSetting[this.tabActiveGroup].betLimit[bet.code][this.tabActiveButton])
            throw new Error('ค่าต้องน้อยกว่าของเอย่นต์')
        }

        /**
         * id กลุ่มที่เลือกแก้ไข
         */
        const groupIds = this.selectedGroups.map((group)=>{
          return group._id
        })

        const updateData = {
          parentAccountId: this.accountId || this.$store.state.account._id,
          accountIds: this.bulkAccountIds,
          groupIds: groupIds,
          betLimit: betLimit,
          limit: this.tabActiveButton
        }

        console.log(updateData)
        AccountSettingService.updateAccountSettingBets(updateData)
        .then((response) => {
          if(response.success) {
            this.resetBulk()
            this.$notify({
              type: 'success',
              title: 'สำเร็จ!',
              text: 'บันทึกเสร็จเรียบร้อย',
            })
          }else{
            throw new Error(response.data)
          }
        })
        .catch((e) => {
          console.log(e.message)
          cAlert({
          ...e,
            title: 'ไม่สำเร็จ!',
            text: e?.message || 'แก้ไขข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
            icon: 'error',
            confirmButtonText: 'OK'
          })
        })
        .finally(() => {
          this.loadAccounts()
        })
      }
      catch(e) {
        cAlert({
          ...e,
          text: e.message,
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }
    },
    resetBulk() {
      this.checkAll = false
      this.inputBulk = JSON.parse(JSON.stringify(inputBulk))
      this.bulkAccountIds = []
    }
  },
  created() {
    this.tabActiveGroupId = this.groups[0]?._id

    if(this.groups.length === 0) {
      this.$store.dispatch('reloadMarketGroups')
    }
  },
  mounted() {
    this.loadAccounts()
  },
  filters: {
    numberFormat(num) {
      if(!num)
        return ''

      return parseFloat(num).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  }
}
</script>
<style lang="scss" scoped>
.breadcrumb {
  background-color: #FFF;
  border-radius: 10px;
}

.nav-link {
  cursor: pointer;
}

.table-accounts {
  thead {
    tr {
      th {
        font-size: 90%;
        vertical-align: middle;
      }
    }
  }
  tbody {
    tr {
      td {
        vertical-align: middle;
        text-align: right;
        line-height: 1;

        div.username {
          font-size: 110%;
        }
        small {
          font-size: 75%;
        }
      }
    }
  }
}

.limit-options {
  button {
    width: 150px;
  }
}
</style>
